import React, { memo } from "react";
import { FaStar } from "react-icons/fa";
import { motion } from "framer-motion";

const Hero: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col relative">
      <section className="flex-grow px-6 pt-8 pb-16 md:pt-8 md:pb-12 relative">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              className="max-w-xl w-full"
            >
              <div className="flex justify-start mb-8">
                <img
                  src="/logo192.png"
                  alt="iScanner Logo"
                  className="h-[70px]"
                  draggable="false"
                />
              </div>
              <div className="text-left">
                <div className="flex gap-4 justify-start mb-6">
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="inline-block px-4 py-2 bg-blue-50 dark:bg-gray-800 rounded-full shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    <span className="text-blue-600 dark:text-blue-400 font-medium text-sm">
                      ✨ New AI Summary
                    </span>
                  </motion.div>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    className="inline-block px-4 py-2 bg-green-50 dark:bg-gray-800 rounded-full shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    <span className="text-green-600 dark:text-green-400 font-medium text-sm">
                      Free
                    </span>
                  </motion.div>
                </div>
                <h1 className="font-epilogue font-bold text-4xl md:text-5xl text-gray-900 dark:text-white mb-4">
                  Transform Your Phone Into a Pro Scanner
                </h1>
                <p className="font-epilogue text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-6">
                  Scan, organize, and share documents with unmatched clarity and
                  convenience
                </p>
              </div>

              <div className="flex justify-start mb-6">
                <a
                  href="https://apps.apple.com/app/id6651820995"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="transition-transform hover:scale-105 duration-200 select-none"
                >
                  <img
                    src="/appstore.svg"
                    alt="Download on the App Store"
                    className="h-[70px]"
                    draggable="false"
                  />
                </a>
              </div>

              <div className="space-y-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.6 }}
                  className="flex flex-col items-start gap-4"
                >
                  <div className="flex items-center gap-4 bg-white dark:bg-gray-800 p-3 rounded-xl shadow-sm">
                    <StarRating />
                    <span className="text-gray-700 dark:text-gray-300 font-medium">
                      4.9 (50K+ reviews)
                    </span>
                  </div>
                  <div className="bg-white dark:bg-gray-800 p-3 rounded-xl shadow-sm">
                    <span className="text-gray-700 dark:text-gray-300 font-medium">
                      🚀 100K+ Downloads
                    </span>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
              className="w-full max-w-sm md:max-w-md"
            >
              <motion.div
                animate={{
                  y: [0, -10, 0],
                  rotate: [-1, 1, -1],
                }}
                transition={{
                  duration: 6,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
                className="relative"
              >
                <div
                  className="relative w-fulloverflow-hidden"
                  style={{ willChange: "transform" }}
                >
                  <img
                    src="/promo.png"
                    alt="iScanner App"
                    className="w-full h-auto"
                    draggable="false"
                  />
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>

      <footer className="app-footer py-4 bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm">
        <p className="text-center text-gray-600 dark:text-gray-400">
          &copy; iScanner {new Date().getFullYear()}
        </p>
      </footer>
    </div>
  );
};

const StarRating = memo(() => (
  <div className="flex">
    {[...Array(5)].map((_, i) => (
      <FaStar key={i} className="text-yellow-400 w-5 h-5" />
    ))}
  </div>
));

export default Hero;
